<template>
    <div>
        <card-header title="Add-On Participants" icon="fa-street-view"/>

        <card-body>
            <card-list>
                <trip-add-on-list-item
                    :card="card"
                    :addon="addon"
                    context
                />
                <subheader-list-item title="Participants" icon="fas fa-street-view"/>

                <reg-add-on-list-item
                    :card="card"
                    :addon="addon"
                    v-for="addon in addon.participants"
                    :active="(child && child.definition.component === 'edit-addon') && child.props.addonId === addon.id.toString()"
                    @click="$openCard('edit-addon', {registrationId: addon.registration_id, addonId: addon.id}, card)"
                    show-user
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import TripListItem from "@/components/TripListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import TripResponseListItem from "@/components/TripResponseListItem";
    import QuestionListItem from "@/components/QuestionListItem";
    import TripAddOnListItem from "@/components/TripAddOnListItem";
    import RegAddOnListItem from "@/components/RegAddOnListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {RegAddOnListItem, TripAddOnListItem, CardBody, TripListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                addon: {
                    participants: []
                }
            };
        },
        methods: {
            loadAddon: function(force) {
                this.$emit('loading', true);
                http.get('/api/addons/' + this.props.addonId, {force}).then(response => {
                    this.addon = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadAddon(true);
            }
        },
        watch: {
            'props.addonId': function() {
                this.loadAddon(true);
            }
        },
        mounted() {
            this.loadAddon();
        }
    };
</script>

<style lang="scss" scoped>

</style>
