<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-layer-plus" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title" v-if="!addon.override_title">{{addon.title}}</div>
            <div class="trip-title" v-if="addon.override_title">{{addon.override_title}}</div>
            <div class="meta" v-if="showUser"><i class="far fa-fw fa-user mr-2"></i>{{addon.last_name}}, {{addon.first_name}}</div>
            <div class="meta" v-if="showUser && addon.second_last_name"><i class="far fa-fw fa-user-friends mr-2"></i>{{addon.second_last_name}}, {{addon.second_first_name}}</div>
            <div class="meta"><i class="far fa-fw fa-layer-group mr-2"></i>{{addon.type | capitalize}}</div>
            <div class="meta" v-if="addon.override_price == null && addon.override_price_double == null">
                <template v-if="double">
                    <i class="far fa-fw fa-user-friends mr-2"></i>{{addon.double_cost | currency}}
                </template>
                <template v-else>
                    <i class="far fa-fw fa-user mr-2"></i>{{addon.single_cost | currency}}
                </template>
            </div>
            <div class="meta" v-else>
                <template v-if="double">
                    <i class="far fa-fw fa-user-friends mr-2"></i>{{addon.override_price_double | currency}}<i class="far fa-cogs ml-2"></i>
                </template>
                <template v-else>
                    <i class="far fa-fw fa-user mr-2"></i>{{addon.override_price | currency}}<i class="far fa-cogs ml-2"></i>
                </template>
            </div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <b-tag type="is-warning" class="stat" v-if="addon.task_complete === 0"><i class="fas fa-tasks"></i></b-tag>
            </div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-1">
                    <data-row mini title="Add-on">{{addon.title}}</data-row>
                    <data-row mini title="Single">{{addon.single_cost | currency}}</data-row>
                    <data-row mini title="Double">{{addon.double_cost | currency}}</data-row>
                    <data-row mini title="Add-on ID">{{addon.addon_id}}</data-row>
                    <data-row mini title="Created">{{addon.created_at | moment('MMMM Do, YYYY, h:mm A')}}</data-row>
                    <data-row mini title="ID">{{addon.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'RegAddOnListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            addon: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            showUser: {
                type: Boolean,
                default: false
            },
            double: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }
</style>
